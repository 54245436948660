import React from 'react'
import { graphql } from 'gatsby'
import NormalizeVacancyData from 'utils/normalize-vacancy-data'
import CareersPageTpl from 'templates/careers/page'

const CareersPage = ({ data: { prismicCareersPage, allPrismicVacancy } }) => {
	const { send_paragraph, tagline, title } = prismicCareersPage.data
	const vacancies = allPrismicVacancy.nodes.map(NormalizeVacancyData)

	const gridImages = prismicCareersPage.data.grid
		.slice(0, 6)
		.map(({ photo: { fixed, url } }) => {
			return { imgUrl: fixed?.src || url }
		})

	return (
		<CareersPageTpl
			content={{ send_paragraph, tagline, title }}
			vacancies={vacancies}
			gridImages={gridImages}
		/>
	)
}

export const query = graphql`
	query prismicVacancyList {
		prismicCareersPage {
			data {
				grid {
					photo {
						url
						fixed(width: 320) {
							src
						}
					}
				}
				send_paragraph
				tagline
				title
			}
		}
		allPrismicVacancy(
			filter: { data: { is_active: { eq: true } } }
			sort: { order: ASC, fields: data___priority }
		) {
			nodes {
				uid
				data {
					location
					title
				}
			}
		}
	}
`

export default CareersPage
