import React from 'react'
import { Root } from 'templates/layout'
import {
	DesignRow,
	Heading1,
	Heading2,
	Heading5,
	IntenseText,
	RegularText,
} from 'components/design'
import LinkWithCube from 'components/link-with-cube'
import RightArrow from 'svg/right-arrow'
import VacanciesList from './list'

/* Corresponding CSS at _careers.css */
const CareersPageTpl = ({
	gridImages,
	vacancies,
	content: { send_paragraph, tagline, title },
}) => (
	<Root>
		<DesignRow
			className="mt-24 md:mt-48 lg:mt-64"
			leftColumn={<Heading1>{title}</Heading1>}
			rightColumn={<IntenseText>{tagline}</IntenseText>}
		/>
		<DesignRow
			className="mt-8 md:mt-12"
			leftColumn={
				<RegularText>{send_paragraph.replace(' to', '\u00a0to')}</RegularText>
			}
			rightColumn={
				<a
					href="mailto:hr@cuubstudio.com"
					className="accent-hover flex items-center justify-between"
				>
					<Heading5>hr@cuubstudio.com</Heading5>
					<div className="h-6 w-6 select-none">
						<RightArrow />
					</div>
				</a>
			}
		/>
		<DesignRow
			className="mt-4 md:mt-20"
			fullWidth={
				<div className="flex flex-wrap justify-between">
					{gridImages.map(({ imgUrl }) => (
						<div
							key={imgUrl}
							className="careers-grid-photo mt-8 aspect-square bg-black/5 bg-cover bg-center lg:mt-0"
							style={{ backgroundImage: `url('${imgUrl}')` }}
						></div>
					))}
				</div>
			}
		/>

		{vacancies.length ? (
			<>
				<DesignRow
					className="mt-12 md:mt-20"
					leftColumn={<Heading2>Currently looking for</Heading2>}
				/>
				<VacanciesList vacancies={vacancies} />
			</>
		) : (
			<>
				<DesignRow
					className="mt-12 md:mt-20"
					leftColumn={<Heading2>Currently we have no openings</Heading2>}
				/>
				<DesignRow
					className="mt-10 mb-16 md:mb-32"
					leftColumn={
						<IntenseText>
							However, we are always looking for new talents to join our
							friendly team.
						</IntenseText>
					}
					rightColumn={
						<>
							<RegularText>
								Don’t hesitate to send us a message, if you want to spark
								a conversation about becoming one of us.
							</RegularText>
							<div className="relative mt-4 md:h-full">
								<LinkWithCube
									className="w-full md:absolute md:bottom-0 lg:bottom-6"
									target="/contacts/"
									type="link"
								>
									Reach out
								</LinkWithCube>
							</div>
						</>
					}
				/>
			</>
		)}
	</Root>
)

export default CareersPageTpl
